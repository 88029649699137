<template>
  <div class="paymentDetail">
    <div class="row justify-content-between align-items-center">
      <div class="col-md-6">
        <base-alerts />
        <h1>{{ title }}</h1>
        <connect-social-account
          @success="success"
          :callback-url="`/${currentLang}/user/register/connect-social`"
        />
        <div class="skip-button">
          <!--<span>"Would you like to link your social media acccounts later? ... press here to continue your registration"</span>-->
          <button 
            class="btn btn-primary custom-btn"
            @click="onSubmit"
          >
            Skip For Now
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="image">
          <img
            src="@/assets/images/payment-detail.png"
            class="img-fluid"
            alt="payment-detail.png-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Trans } from "@/plugins/Translation";
import { mapActions } from "vuex";
import { MEDIA_ACCOUNT_TEMP } from "@/graphql/user/mutations";

export default {
  components: {
    connectSocialAccount: () =>
      import(
        /* webpackChunkName: "connectSocialAccount" */ "@/components/user/register/ConnectSocialAccount.vue"
      ),
  },
  data() {
    return {
      title: this.$i18n.t('register.Connect Social'),
      processing: false,
      btnText: "Save",
      showUser: false,
      currentLang: Trans.currentLanguage,
      mediaAccount: {
        account_id: 0,
        access_token: null,
        refresh_token: null,
        provider_user_id: null,
      },
    };
  },
  methods: {
    ...mapActions("mediaAccounts", ["setMediaAccount"]),
    ...mapActions(["updateUser"]),
    success() {
      // this.$router.push("/user/register/story-price");
      this.$router.push(this.$i18nRoute({name: "userStoryPrice"}));
    },
    async onSubmit() {
      await this.$apollo
        .mutate({
          mutation: MEDIA_ACCOUNT_TEMP,
          variables: this.mediaAccount,
        })
        .then((data) => {
          if (data) {
            if (
              data.data.updateMediaAccountTemp.state == "added" ||
              data.data.updateMediaAccountTemp.state == "updated"
            ) {
              this.setMediaAccount();
              this.updateUser();
              // this.$router.push("/user/register/story-price");
              this.$router.push(this.$i18nRoute({name: "userStoryPrice"}));
            }
          }
        })
        .catch((e) => {
          this.showMediaConnectButtons = true;
          this.isDataAddedUpdated = false;
          this.handleError(e);
        });
      // this.$router.push("/user");
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .invalid-feedback {
  position: static !important;
}
.skip-button {
  margin-top: 70px;
}
.custom-btn {
  width: 160px
}
</style>
